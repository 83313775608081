import React from "react";
import { createMarkup } from "../../utils/Utils";
import Skeleton from "react-loading-skeleton";
import { useGetPolicyQuery } from "../../utils/api/condition/condition.api";

function PolicyPage() {
  const { data = [], isLoading, refetch } = useGetPolicyQuery();

  React.useEffect(() => {
    if (!isLoading) {
      refetch();
    }
  }, []);
  return (
    <div className="landing-page-component">
      <div className="page-content">
        <div className="container-banner-policy">
          <h2 className="title-banner-policy">
            {data?.length ? data[0]?.titre : "Politique de confidentialité"}
          </h2>
        </div>
        <div className="container">
          <div className="container-text-policy">
            {!!isLoading && <Skeleton count={100} width={`100%`} />}
            {!isLoading && (
              <p
                className="text-policy"
                dangerouslySetInnerHTML={createMarkup(
                  data?.length ? data[0]?.text : ""
                )}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PolicyPage;
