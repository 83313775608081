import React, { useEffect, useState } from "react";
import "./HistoriquesAdmin.css";
import BreadCrumb from "../../shared/BreadCrumb";
import { FaEye } from "react-icons/fa";
import DetailHistoriqueModal from "./modals/DetailHistoriqueModal";
import { usePagination, useSearch } from "../../../utils/hooks";
import { formatterDateFrench , formatHour } from "../../../utils/Utils";
import {useGetListHistoriqueQuery} from '../../../utils/api/user/user.api';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AlertInfo } from '../../common/Alert';
import TableSkeleton from '../../common/TableSkeleton';

import moment from "moment";

const HistoriquesAdmin = () => {
  const [show, setShow] = useState(false);
  const [historique, setHistorique] = useState<any>();
  const handleShow = () => setShow(true);
  const { limit, page, onChange } = usePagination(10);
  const [type, setType] = useState<string>("week");
  const [date, setDate] = useState<Date>(new Date());
  const [query, setQuery] = useState("");
  
  const { data, isLoading , refetch } = useGetListHistoriqueQuery({
    page: page,
    limit: limit,
    filter: type === "custom" ? "" : type,
    date: moment(date).format("DD-MM-YYYY"),
    q: query
  });

  const today = moment();
  const yesterday = moment().subtract(1, 'day');

  const showModale =(item) => {
    setHistorique(item)
    handleShow()
  };

  const onChangeDate = (start: any) => {
    setDate(start);
  };

  useEffect(() => {
    if (query) {
      refetch()
    }
  }, [query])
  


  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <BreadCrumb pageUrl="/admin/historiques" pageName="Historiques" />
        <div className="custom-profil-content mb-2">
          <div className="top-curved-container">
            <h3 className="custom-top-title">Historiques</h3>
          </div>
        </div>
        <div className="content-chart-title mb-4">
          <h3 className="chart-item-admin-title mb-0">
            Filtrer par date
          </h3>
          <div className="chart-item-admin-week-indicator-container">
            <DatePicker
              selected={date}
              onChange={(date: any) => onChangeDate(date)}
              startDate={date}
              locale="fr"
              className="chart-item-admin-week-indicator"
              showMonthYearPicker={type === "month"}
              showYearPicker={type === "year"}
              dateFormat={
                type === "month"
                  ? "MMMM/yyyy"
                  : type === "year"
                    ? "yyyy"
                    : "dd/MM/yyyy"
              }
            />
            <div className="chart-item-admin-week-indicator-statut"></div>
          </div>
          <div className="content-select-date-graph gap-3 d-flex">
            <button className={`fc-dayGridMonth-button fc-button fc-button-primary btn-type-date ${type === "year" ? "fc-button-active" : ""
              }`}
              onClick={() => setType("year")}>
              Année
            </button>
            <button className={`fc-dayGridMonth-button fc-button fc-button-primary btn-type-date ${type === "month" ? "fc-button-active" : ""
              }`}
              onClick={() => setType("month")}>
              Mois
            </button>
            <button className={`fc-timeGridWeek-button fc-button fc-button-primary btn-type-date ${type === "week" ? "fc-button-active" : ""
              }`}
              onClick={() => setType("week")}>Semaine
            </button>
          </div>
          <div className='mon-doc-btn-filter-container gap-4 pb-2'>
            <input
              type="search"
              className="form-control form-control-search"
              placeholder="Tapez pour rechercher"
              onChange={(e) => setQuery(e?.target?.value)}
            />
          </div>
        </div>
        {(!isLoading && data?.results && data?.results?.length > 0) ?
          data?.results?.map((group:any) =>(
            <div className="container-historiques-admin-page mb-3">
                <div className="content-historique-admin-card-item">
                  <div className="date-historique-admin-card">
                    { moment(group?.date).isSame(today , 'day') ? ( 
                      <p className="title-date-historique-admin-card">Aujourd’hui</p>
                    ):(
                      moment(group?.date).isSame( yesterday , 'day') ? 
                        <p className="title-date-historique-admin-card">Hiere</p>
                      :
                        <p className="title-date-historique-admin-card">{formatterDateFrench(group?.date)}</p>
                    ) }
                  </div>
                  <div className="content-text-card-historique-admin">
                    {group?.historiques?.map((historique) =>(
                      <div className="card-item-info-historique-admin mb-3">
                        <div className="text-card-item-info-historique-admin">
                          <span className="text-hour-historique-admin pe-2">{formatHour(historique?.created_at)}</span>
                          <span className="text-info-historique-admin">
                            {historique?.content}
                            {historique?.medecin && (
                              <>
                                <span
                                  style={{
                                    color: "#EF802F",
                                    paddingRight: 5,
                                    paddingLeft: 5,
                                  }}
                                >
                                  Dr {historique?.medecin?.prenom} {historique?.medecin?.nom}
                                </span>
                                <span>et la patiente</span>
                              </>
                            )}
                            <span
                              style={{
                                color: "#EF802F",
                                paddingRight: 5,
                                paddingLeft: 5,
                              }}
                            >
                              {historique?.user?.prenom} {historique?.user?.nom}
                            </span>
                          </span>
                        </div>
                        <div className="content-btn-detail-historique-admin">
                          <button
                            className="btn btn-detail-historique-admin"
                            type="button"
                            onClick={(e) => showModale(historique)}
                          >
                            <span className="pe-2">Voir les détails</span>
                            <span>
                              <FaEye />
                            </span>
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
            </div>
          ))
        :
        <AlertInfo message="Aucune donnée trouvée" />
        }
      </div>
      <DetailHistoriqueModal historique={historique} show={show} setShow={setShow} />
    </div>
  );
};

export default HistoriquesAdmin;
